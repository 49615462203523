import React, { useEffect, useState } from "react";

const ResizePage = () => {
	const [height, setHeight] = useState(200);
	useEffect(() => {
		window.addEventListener("message", (event) => {
			if (typeof event.data === "string") {
				try {
					const data = JSON.parse(event.data);
					if (data && data.height) {
						// console.log("height", data.height);
						// console.log("url", data.url);
						setHeight(data.height);
					}
				} catch (e) {}
			}
		});
	}, []);
	return (
		<div>
			<p>
				Altura actual: <strong>{height}</strong>px
			</p>
			<iframe
				src="/"
				width="90%"
				height={height}
				style={{ border: "10px solid lightblue" }}
			/>
		</div>
	);
};

export default ResizePage;
